<template>
    <v-flex xs12 sm12 md6>
        <v-card class="mx-auto">
            <v-container>
                <v-row>
                    <v-col cols="1">
                        <!-- <IconBase><temperatureIcon /> </IconBase> TODO -->
                    </v-col>
                    <v-col cols="6">
                        <div class="text-h6 title">{{ device.displayName }}</div>
                    </v-col>
                </v-row>
            </v-container>

            <v-card-text>
                <div v-if="device.isLive">
                    <v-row class="mb-4" justify="space-between">
                        <v-col class="text-left">
                            <span class="display-3 font-weight-light" v-text="deviceValue"></span>
                            <span class="subheading font-weight-light mr-1">{{ unitSymbol }}</span>
                        </v-col>
                    </v-row>
                </div>
                <v-tooltip v-else bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon color="amber lighten-1" v-bind="attrs" v-on="on">
                            mdi-alert
                        </v-icon>
                    </template>
                    <span class="white-text">{{ $t("messages.alert.cannotConnectToTopic") }}</span>
                </v-tooltip>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <favoriteIcon :favorite="device.isFavorite" @click="toggleFavorite" />
            </v-card-actions>
        </v-card>
    </v-flex>
</template>

<script>
import favoriteIcon from "../icons/favorite.vue";
// import IconBase from "../icons/IconBase";
// import temperatureIcon from "../icons/IconTemperature";

export default {
    props: ["device"],
    // components: { IconBase, favoriteIcon, temperatureIcon },
    components: { favoriteIcon },
    computed: {
        deviceValue() {
            const topicIndex = this.getTopicIndex("subscriber");
            return parseFloat(this.device.topics[topicIndex].value);
        },
        unitSymbol() {
            switch (this.device.deviceType) {
                case 'temperature':
                    return '°C'
                default:
                    return ''
            }
        }
    },
    methods: {
        async toggleFavorite() {
            await this.$store.dispatch("custSetup/toggleFavorite", {
                deviceId: this.device.id,
            });
        },
        getTopicIndex(type) {
            if (type === "subscriber") {
                return this.device.topics.findIndex(
                    (topic) => topic.type === "subscriber"
                );
            }
        }
    }
};
</script>

<style scoped>
.title {
    color: #607d8b;
}
</style>
