<template>
  <v-flex xs12 sm6 md6>
    <v-card class="mx-auto">
      <v-container>
        <v-row>
          <v-col cols="1">
            <!-- <IconBase><lampIcon v-if="isLamp" /> </IconBase> -->
          </v-col>
          <v-col cols="9">
            <div class="text-h6 title">{{ device.displayName }}</div>
          </v-col>
          <v-col class="d-flex" cols="2">
            <v-tooltip v-if="!device.isLive" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon color="amber lighten-1" v-bind="attrs" v-on="on">
                  mdi-alert
                </v-icon>
              </template>
              <span class="white-text">{{
                $t("messages.alert.cannotConnectToTopic")
              }}</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col>
            <v-slider
              v-model="value"
              :tick-labels="ticksLabels"
              step="1"
              ticks="always"
              :max="maxTicks"
              tick-size="4"
              @change="handleChange"
            ></v-slider>
          </v-col>
        </v-row>
      </v-container>
      <v-card-actions>
        <v-spacer></v-spacer>
        <favoriteIcon :favorite="device.isFavorite" @click="toggleFavorite" />
      </v-card-actions>
    </v-card>
  </v-flex>
</template>

<script>
import favoriteIcon from "../icons/favorite.vue";
// import IconBase from "../icons/IconBase";
// import lampIcon from "../icons/IconLamp";

export default {
  props: ["device"],
//   components: { IconBase, favoriteIcon, lampIcon, PulseButton },
  components: { favoriteIcon },
  data: () => {
    return {
      value: null,
    };
  },
  computed: {
    deviceCurrentValue() {
      const topicIndex = this.getTopicIndex("subscriber");
      return this.options.findIndex(option => option.value === this.device.topics[topicIndex].value);
    },
    options() {
      const topicIndex = this.getTopicIndex("publisher");
      return this.device.topics[topicIndex].options;
    },
    ticksLabels() {
        return this.options.map(option => option.label !== undefined ? option.label : option.value)
    },
    maxTicks(){
        return this.ticksLabels.length-1
    }
  },
  created() {
    this.value = this.deviceCurrentValue;
  },
  watch: {
    deviceCurrentValue() {
      const topicIndex = this.getTopicIndex("subscriber");
      this.value = this.device.topics[topicIndex].value;
    },
  },
  methods: {
    handleChange(index) {
      const pubTopicIndex = this.getTopicIndex("publisher");
      const pubTopicName = this.device.topics[pubTopicIndex].topicName;

      const data = this.options[index].value;

      //Send to broker
      this.$store.getters["mqtt/mqttClient"].publish(
        pubTopicName,
        data,
        { qos: 1, retain: true },
        (err) => {
          if (!err) {
            //TODO
          } else {
            //TODO
          }
        }
      );
    },
    async toggleFavorite() {
      await this.$store.dispatch("custSetup/toggleFavorite", {
        deviceId: this.device.id,
      });
    },

    getTopicIndex(type) {
      if (type === "subscriber") {
        return this.device.topics.findIndex(
          (topic) => topic.type === "subscriber"
        );
      } else if (type === "publisher") {
        return this.device.topics.findIndex(
          (topic) => topic.type === "publisher"
        );
      }
    },
  },
};
</script>

<style scoped>
.v-input--selection-controls {
  margin: 0 !important;
}

.v-application--is-ltr .v-input--selection-controls__input,
.v-input--selection-controls__input {
  margin-right: 0 !important;
}

.title {
  color: #607d8b;
}

.center {
  display: block;
  margin: 0 auto;
}
</style>