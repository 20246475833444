<template>
  <v-flex grow xs12>
    <v-card class="elevation-6">
      <v-toolbar elevation="0" class="bottom-line">
        <v-toolbar-title>{{ formTitle }}</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-form @submit.prevent="handleFormSubmit">
          <v-text-field
            prepend-icon="mdi-email"
            name="login"
            label="Email"
            type="text"
            v-model="email"
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-btn type="submit" color="primary" :loading="loading">{{
            buttonLabel
          }}</v-btn>
        </v-form>
        <div class="d-flex flex-row mt-5">
          <v-spacer />
          <router-link :to="{ name: 'Login' }" class="text-decoration-none float-right"
            >{{$t("labels.authentication.login")}}</router-link
          >
        </div>
      </v-card-text>
      <v-alert v-show="errorMessages.length" elevation="4" type="error">
        <ul>
          <li v-for="error in errorMessages" :key="error">
            {{ error }}
          </li>
        </ul>
      </v-alert>
    </v-card>
  </v-flex>
</template>

<script>
import validator from "validator";

export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      email: null,
      errorMessages: [],
    };
  },
  computed: {
    buttonLabel() {
      return this.$t("labels.authentication.button.send");
    },
    formTitle() {
      return this.$t("forms.authentication.resetpassword");
    },
  },
  methods: {
    handleFormSubmit() {
      this.errorMessages = [];
      this.isValid = this.validateForm();
      if (this.isValid) {
        this.$emit("submit", {
          email: this.email,
        });
      }
    },
    validateForm() {
      const emailIsValid = validator.isEmail(this.email);

      if (!emailIsValid) {
        this.errorMessages.push(this.$t("messages.alert.invalidEmail"));
      }
      return emailIsValid;
    },
  },
};
</script>

<style>
.bottom-line {
  border-bottom: thin solid rgba(0, 0, 0, 0.12) !important;
}
</style>