import mutations from "./mutations.js"
import getters from "./getters.js"
import actions from "./actions.js"

export default {
    namespaced: true,
    state: {
        mqttClient: null,
        host: '',
        username: null,
        password: null,
        clientId: null, // "Session ID" when connecting to the broker
        customerId: null
    },
    mutations,
    getters,
    actions
}