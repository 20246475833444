<template>
  <v-container grid-list-md>
    <v-layout row wrap>
      <component
        v-for="device in devices"
        :key="device.id"
        :is="device.type"
        :device="device"
      />
    </v-layout>
  </v-container>
</template>

<script>
import Toggle from "./ToggleDevice.vue";
import Temperature from "./TemperatureDevice.vue";
import TemperatureOnOff from "./TemperatureOnOffDevice.vue";
import TemperaturePulse from "./TemperaturePulseDevice.vue";
import Dimmer from "./DimmerDevice.vue";
import Screen from "./ScreenDevice.vue";
import SwimmingPoolShutter from "./SwimmingPoolShutter.vue";
import Pulse from "./PulseDevice.vue";
import Sensor from "./Sensor.vue";
import Selector from "./Selector.vue";

export default {
  props: ["devices"],
  components: { Toggle, Temperature, TemperatureOnOff, Dimmer, Screen, Pulse, Sensor, Selector, TemperaturePulse, SwimmingPoolShutter },
};
</script>