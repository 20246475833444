import mqtt from 'mqtt';
import { MqttBrokerConfig } from '@/config.js';

export default {
    async connectToBroker(context) {
        const mqttCredentials = context.getters.mqttCredentials;
        const details = {
            hostname: MqttBrokerConfig.HOST,
            port: process.env.NODE_ENV === 'development' ? MqttBrokerConfig.SecurePort : MqttBrokerConfig.SecurePort,
            protocol: process.env.NODE_ENV === 'development' ? 'wss' : 'wss',
            clientId: 'secureme-' + Math.random(), //Random id because when a user is logged in with more then 2 devices, one of them will not work.
            username: mqttCredentials.username,
            password: mqttCredentials.password,
            connectTimeout: 50000,
            protocolId: 'MQTT'
        };

        const client = mqtt.connect(details);

        context.commit('setMqttClient', { client });
    },
    async disconnectFromBroker(context) {
        context.commit('endMqttConnection');
    },
    storeMqttCredentials(context, credentials) {
        context.commit('saveMqttCredentialsLocally', credentials);
    }
}