<template>
  <!-- Current email -->
  <!-- Current passord, new password, save button -->
  <v-container>
    <v-row>
      <v-col cols="12" sm="6">
        <v-text-field
          prepend-icon="mdi-email"
          name="login"
          :label="$t('labels.email')"
          disabled
          :value="email"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-form @submit.prevent="handleFormSetNewpassword">
      <v-row>
        <v-col cols="12" sm="6">
          <v-text-field
            prepend-icon="mdi-lock"
            v-model="currentPassword"
            :error="!!inputErrors.currentPassword"
            :error-messages="inputErrors.currentPassword"
            :label="$t('labels.currentPassword')"
            type="password"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6">
          <v-text-field
            prepend-icon="mdi-lock"
            v-model="newPassword"
            :error="!!inputErrors.newPassword"
            :error-messages="inputErrors.newPassword"
            :label="$t('labels.newPassword')"
            type="password"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            prepend-icon="mdi-lock"
            v-model="newPasswordCheck"
            :error="!!inputErrors.newPasswordCheck"
            :error-messages="inputErrors.newPasswordCheck"
            :label="$t('labels.confirmNewPassword')"
            type="password"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-spacer />
        <v-col cols="12">
          <div class="d-flex flex-row">
            <v-spacer></v-spacer>
            <v-btn type="submit" color="primary" :loading="loading"
              >{{$t("labels.save")}}</v-btn
            >
          </div>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import { validatePassword } from "../../../utils/formValidations";

export default {
  props: ["email"],
  data() {
    return {
      currentPassword: "",
      newPassword: "",
      newPasswordCheck: "",
      loading: false,
      inputErrors: {
        currentPassword: "",
        newPassword: "",
        newPasswordCheck: "",
      },
    };
  },
  methods: {
    async handleFormSetNewpassword() {
      this.loading = true;
      let error = false;
      const inputErrors = {
        currentPassword: "",
        newPassword: "",
        newPasswordCheck: "",
      };
      if (!validatePassword(this.currentPassword)) {
        inputErrors.currentPassword = this.$t("messages.alert.invalidPassword");
        error = true;
      }
      if (!validatePassword(this.newPassword)) {
        inputErrors.newPassword = this.$t("messages.alert.invalidPassword");
        error = true;
      }
      if (!validatePassword(this.newPasswordCheck)) {
        inputErrors.newPasswordCheck = this.$t("messages.alert.invalidPassword");
        error = true;
      }
      if (error) {
        this.loading = false;
        this.inputErrors = inputErrors;
        return;
      }
      if (this.newPasswordCheck !== this.newPassword) {
        this.inputErrors.newPasswordCheck =
          this.$t("messages.alert.invalidNewPasswordCheck");
        this.loading = false;
        return;
      }

      const credentials = {
        email: this.email,
        password: this.currentPassword,
        newPassword: this.newPassword,
      };

      try {
        await this.$store.dispatch("auth/setNewPassword", credentials);
        this.$emit("success", { message: this.$t("messages.success.passwordChanged") });
      } catch (error) {
        this.$emit("error", error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>