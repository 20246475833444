<template>
  <v-flex xs12 sm6 md6>
    <v-card class="mx-auto">
      <v-container>
        <v-row>
          <v-col cols="1">
            <IconBase><lampIcon v-if="isLamp" /> </IconBase>
          </v-col>
          <v-col cols="5">
            <div class="text-h6 title">{{ device.displayName }}</div>
          </v-col>
          <v-col class="d-flex" cols="6">
            <v-spacer></v-spacer>
            <v-switch
              inset
              v-if="device.isLive"
              @change="handleSwitchChange"
              v-model="value"
            ></v-switch>
            <v-tooltip v-else bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon color="amber lighten-1" v-bind="attrs" v-on="on">
                  mdi-alert
                </v-icon>
              </template>
              <span class="white-text">{{ $t("messages.alert.cannotConnectToTopic") }}</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-container>
      <v-card-actions>
        <v-spacer></v-spacer>
        <favoriteIcon :favorite="device.isFavorite" @click="toggleFavorite" />
      </v-card-actions>
    </v-card>
  </v-flex>
</template>

<script>
import favoriteIcon from "../icons/favorite.vue";
import IconBase from "../icons/IconBase";
import lampIcon from "../icons/IconLamp";

export default {
  props: ["device"],
  components: { IconBase, favoriteIcon, lampIcon },
  data: () => {
    return {
      value: null,
    };
  },
  computed: {
    deviceValue() {
      const topicIndex = this.device.topics.findIndex(
        (topic) => topic.type === "subscriber"
      );
      return this.device.topics[topicIndex].value === "1";
    },
    isLamp() {
      return this.device.deviceType === "Lamp";
    }
  },
  created() {
    this.value = this.deviceValue;
  },
  watch: {
    deviceValue() {
      const topicIndex = this.device.topics.findIndex(
        (topic) => topic.type === "subscriber"
      );
      this.value = this.device.topics[topicIndex].value === "1";
    },
  },
  methods: {
    handleSwitchChange() {
      const pubTopicIndex = this.device.topics.findIndex(
        (topic) => topic.type === "publisher"
      );
      const pubTopicName = this.device.topics[pubTopicIndex].topicName;

      //Send to broker
      this.$store.getters["mqtt/mqttClient"].publish(
        pubTopicName,
        this.value ? "1" : "0",
        { qos: 1, retain: true },
        (err) => {
          if (!err) {
            //TODO
          } else {
            //TODO
          }
        }
      );
    },
    async toggleFavorite() {
      await this.$store.dispatch("custSetup/toggleFavorite", {
        deviceId: this.device.id,
      });
    },
  },
};
</script>

<style scoped>
.v-input--selection-controls {
  margin: 0 !important;
}

.v-application--is-ltr .v-input--selection-controls__input,
.v-input--selection-controls__input {
  margin-right: 0 !important;
}

.title {
  color: #607d8b;
}

</style>