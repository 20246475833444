import Vue from 'vue'
import Vuex from 'vuex'
import mqttModule from "./modules/mqtt/index.js";
import custSetup from "./modules/customerSetup/index.js"
import authModule from "./modules/auth/index.js"

Vue.use(Vuex)

const store = new Vuex.Store({
    modules: {
        mqtt: mqttModule,
        custSetup: custSetup,
        auth: authModule
    }
});

export default store;