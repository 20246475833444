import Vue from 'vue';
import VueRouter from 'vue-router';
import TheDashboard from '../pages/TheDashboard.vue';
import TheAuthenticationPage from '../pages/TheAuthenticationPage.vue';
import TheProfilePage from '../pages/TheProfilePage.vue';
import TheNotFoundPage from '../pages/TheNotFoundPage.vue';
import TheInvitationPage from '../pages/admin/TheInvitationPage.vue';
import Favorites from "../views/pages/Dashboard/Favorites/Favorites.vue";
import Groups from "../views/pages/Dashboard/Groups/Groups.vue";
import Scenes from "../views/pages/Dashboard/Scenes/Scenes.vue";
import firebase from 'firebase/app';
import 'firebase/auth';

Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'Dashboard',
    component: TheDashboard,
    meta: {
        requiresAuth: true
    },
    children: [{
        path: 'favorieten',
        name: 'Favorites',
        component: Favorites,
        meta: {
            requiresAuth: true
        },
    }, {
        path: 'ruimten',
        name: 'Groups',
        component: Groups,
        meta: {
            requiresAuth: true
        },
    },
    {
        path: 'sferen',
        name: 'Scenes',
        component: Scenes,
        meta: {
            requiresAuth: true
        },
    }
    ]
},
{
    path: '/aanmelden',
    name: 'Login',
    component: TheAuthenticationPage,
},
{
    path: '/registreren',
    name: 'Signup',
    component: TheAuthenticationPage,
},
{
    path: '/wachtwoord-herstellen',
    name: 'ResetPassword',
    component: TheAuthenticationPage,
},
{
    path: '/wachtwoord-herstellen-aanvragen',
    name: 'RequestResetPassword',
    component: TheAuthenticationPage,
},
{
    path: '/invite',
    name: 'Invite',
    component: TheInvitationPage,
},
{
    path: '/profiel',
    name: 'Profile',
    component: TheProfilePage,
    meta: {
        requiresAuth: true
    },
},
{
    path: ':notFound(.*)',
    component: TheNotFoundPage
},
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach(async function (to, from, next) {
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
    
    firebase.auth().onAuthStateChanged(user => {
        if (requiresAuth && !user) {
            next({ name: 'Login' });
        } else if ((to.name === 'Login' || to.name === 'Signup') && user) {
            next({ name: 'Dashboard' });
        } else {
            next();
        }
    });
})

export default router