<template>
  <div>
    <v-row dense>
      <v-col
        v-for="group in _groups"
        :key="group.name"
        cols="12"
        sm="12"
        md="6"
        lg="4"
        xl="4"
      >
        <v-card hover tile @click="$emit('groupSelected', group.id)">
          <v-card-title>{{ group.name }}</v-card-title>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: ["groups"],
  methods: {
    compare(a, b) {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    }
  },
  computed: {
    _groups() {
      return [...this.groups].sort(this.compare);
    }
  }
};
</script>