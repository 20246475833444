<template>
  <v-app>
    <v-navigation-drawer v-if="isAuthenticated" v-model="drawer" app>
      <v-list>
        <v-list-item color="#607d8b" link :to="{ name: 'Favorites' }">
          <v-list-item-icon>
            <v-icon>mdi-view-dashboard</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title> {{ $t("labels.dashboard") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item color="#607d8b" link :to="{ name: 'Profile' }">
          <v-list-item-icon>
            <v-icon>mdi-account</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title> {{ $t("labels.profile") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <template v-slot:append>
        <div class="pa-2">
          <v-btn block @click="logout"
            >{{ $t("labels.authentication.logout") }}
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>

    <v-app-bar flat color="#67B22F" app>
      <v-app-bar-nav-icon
        v-if="isAuthenticated"
        @click="drawer = !drawer"
      ></v-app-bar-nav-icon>

      <v-toolbar-title class="toolbar-title">{{
        $t("application_name")
      }}</v-toolbar-title>
    </v-app-bar>
    <v-main class="background">
      <v-container fluid>
        <router-view />
      </v-container>
    </v-main>

    <v-footer app> </v-footer>
  </v-app>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: "App",
  created() {
    this.$store.dispatch("auth/tryLogin");
  },
  computed: {
    isAuthenticated() {
      return mapState(['isAuthenticated']);
    }
  },
  data: () => {
    return {
      drawer: false,
    };
  },
  methods: {
    logout() {
      this.$store.dispatch("auth/logout");
      this.drawer = false;
      this.$router.replace({ name: "Login" });
    },
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap");

html,
body {
  font-family: "PT Sans", sans-serif;
}

#app {
  font-family: "PT Sans", sans-serif;
}

.background {
  background-color: #f2f2f2;
}
.toolbar-title {
  color: #f2f2f2;
}
p,
h1,
h2,
h3,
h4,
h5,
h6,
span,
a,
.v-card__title {
  color: #607d8b;
}
.white-text {
  color:white !important;
}
</style>