export default {
    details(state) {
        return state;
    },
    mqttClient(state) {
        return state.mqttClient;
    },
    mqttCredentials(state) {
        return {
            username: state.username,
            password: state.password
        };
    },
    mqttCredentialsAvailable(state) {
        return state.username && state.password
    }
}