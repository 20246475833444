import validator from "validator";

const validatePassword = (password) => {
    return validator.isStrongPassword(password, {
        minSymbols: 0,
      });
}

const validateEmail = (email) => {
    return validator.isEmail(email);
}

export {validateEmail, validatePassword}