<template>
  <v-flex xs12 sm3 md6>
    <v-card class="mx-auto">
      <v-container>
        <v-row>
          <v-col cols="1">
            <IconBase><lampIcon v-if="isLamp" /> </IconBase>
          </v-col>
          <v-col cols="9">
            <div class="text-h6 title">{{ device.displayName }}</div>
          </v-col>
          <v-col class="d-flex" cols="2">
            <v-tooltip v-if="!device.isLive && withFeedback" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon color="amber lighten-1" v-bind="attrs" v-on="on">
                  mdi-alert
                </v-icon>
              </template>
              <span class="white-text">{{
                $t("messages.alert.cannotConnectToTopic")
              }}</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col>
            <pulse-button
              class="center"
              @click="handlePulseClick"
              :isActive="deviceCurrentValue"
              :showIcon="withFeedback"
            />
          </v-col>
        </v-row>
      </v-container>
      <v-card-actions>
        <v-spacer></v-spacer>
        <favoriteIcon :favorite="device.isFavorite" @click="toggleFavorite" />
      </v-card-actions>
    </v-card>
  </v-flex>
</template>

<script>
import PulseButton from "../buttons/PulseButton.vue";
import favoriteIcon from "../icons/favorite.vue";
import IconBase from "../icons/IconBase";
import lampIcon from "../icons/IconLamp";

export default {
  props: ["device"],
  components: { IconBase, favoriteIcon, lampIcon, PulseButton },
  data: () => {
    return {
      value: null,
    };
  },
  computed: {
    deviceCurrentValue() {
      if (this.withFeedback) {
        const topicIndex = this.getTopicIndex("subscriber");
        return this.device.topics[topicIndex].value === "1"  || this.device?.topics[topicIndex]?.value === 'TRUE';
      }
      return null;
    },
    isLamp() {
      return this.device.deviceType === "Lamp";
    },
    buttonColor() {
      if (this.withFeedback) {
        return this.value ? "#84DC65" : "#E16B6B";
      } else {
        return "#13AAAA";
      }
    },
    withFeedback() {
      return this.getTopicIndex("subscriber") >= 0;
    },
  },
  created() {
    this.value = this.deviceCurrentValue;
  },
  watch: {
    deviceCurrentValue() {
      if (this.withFeedback) {
        const topicIndex = this.getTopicIndex("subscriber");
        this.value = this.device.topics[topicIndex].value === "1";
      }
    },
  },
  methods: {
    handlePulseClick() {
      const pubTopicIndex = this.getTopicIndex("publisher");
      const pubTopicName = this.device.topics[pubTopicIndex].topicName;

      //Send to broker
      this.$store.getters["mqtt/mqttClient"].publish(
        pubTopicName,
        "1",
        { qos: 1, retain: true },
        (err) => {
          if (!err) {
            //TODO
          } else {
            //TODO
          }
        }
      );
    },
    async toggleFavorite() {
      await this.$store.dispatch("custSetup/toggleFavorite", {
        deviceId: this.device.id,
      });
    },

    getTopicIndex(type) {
      if (type === "subscriber") {
        return this.device.topics.findIndex(
          (topic) => topic.type === "subscriber"
        );
      } else if (type === "publisher") {
        return this.device.topics.findIndex(
          (topic) => topic.type === "publisher"
        );
      }
    },
  },
};
</script>

<style scoped>
.v-input--selection-controls {
  margin: 0 !important;
}

.v-application--is-ltr .v-input--selection-controls__input,
.v-input--selection-controls__input {
  margin-right: 0 !important;
}

.title {
  color: #607d8b;
}

.center {
  display: block;
  margin: 0 auto;
}
</style>