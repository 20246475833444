<template>
  <div>
    <!-- <h1>Favorieten</h1> -->
    <v-row align="stretch" justify="space-around" v-if="this.favoriteDevices.length === 0"> 
      <v-col cols="5" align-self="center">
        <p>Er zijn geen favorieten geselecteerd.
          <ul>
            <li>Ga naar ruimten</li>
            <li>Selecteer de gewenste ruimte</li>
            <li>Klik op het favorieten-icoon rechtsonder een kaart</li>
          </ul>
        </p>
      </v-col>
    </v-row>
    <v-row v-else v-for="group in groupsWithDevices" :key="group.id">
      <v-col cols="11">
        <h2>{{ group.name }}</h2>
      </v-col>
      <DeviceList :devices="devicesOfGroup(group.id)" />
    </v-row>
  </div>
</template>

<script>
import DeviceList from "../../../../components/devices/DevicesList.vue";
export default {
  components: { DeviceList },
  created() {
    this.groups = this.$store.getters["custSetup/groups"];
  },
  computed: {
    favoriteDevices() {
      return this.$store.getters["custSetup/favoriteDevices"];
    },
    groupsWithDevices() {
      return this.groups.filter(
        (group) => this.devicesOfGroup(group.id).length > 0
      );
    },
  },
  methods: {
    devicesOfGroup(groupId) {
      return this.favoriteDevices.filter(
        (device) => device.groupId === groupId
      );
    },
  },
  data: () => {
    return {
      groups: [],
      width: "600px",
    };
  },
};
</script>