<template>
  <v-btn icon @click="$emit('click')">
    <v-icon dense :color="color">{{ icon }}</v-icon>
  </v-btn>
</template>

<script>
export default {
  props: {
    favorite: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: "#67B22F",
    },
  },
  computed: {
    icon() {
      return this.favorite ? "mdi-star" : "mdi-star-outline";
    },
  }
};
</script>