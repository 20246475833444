import axios from "axios";
import { ServerConfig } from "../../config";

const serverProtocol = process.env.NODE_ENV === 'development' ? 'http' : 'https';
const serverPort = process.env.NODE_ENV === 'development' ? ServerConfig.PORT : ServerConfig.SecurePort;

const sendVerificationEmailRequest = (emailVariables) =>
    axios
    .post(
        `${serverProtocol}://${ServerConfig.DOMAIN}:${serverPort}/api/${ServerConfig.API_VERSION}/user/invite`,
        emailVariables
    )
    .then((response) =>
        response
    )
    .catch((error) => {
        throw error
    });

const sendResetPasswordEmailRequest = async(email) => {
    try {
        const response = await axios
            .post(`${serverProtocol}://${ServerConfig.DOMAIN}:${serverPort}/api/${ServerConfig.API_VERSION}/user/resetpassword`, email);
        return response
    } catch (e) {
        if (e.response) {
            // client received an error response (5xx, 4xx)
            throw new Error('Er is een fout op de server waardoor er nu geen instructies kunnen verstuurd worden om een nieuw wachtwoord aan te maken. Probeer later opnieuw.')
        } else if (e.request) {
            // client never received a response, or request never left
            throw new Error('Er is een probleem met het netwerk of het bereiken van de server. Probeer later opnieuw.')
        } else {
            // anything else
            throw new Error('Er is iets fout gelopen bij het versturen van de instructies om een nieuw wachtwoord aan te maken. Probeer later opnieuw.')
        }
    }
};

export { sendVerificationEmailRequest, sendResetPasswordEmailRequest }