import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store/index.js';
import vuetify from './plugins/vuetify';

import i18n from './translations/i18n'
import firebase from 'firebase/app';
import 'firebase/analytics';
import 'firebase/auth';
import { FirebaseConfig } from './config';


Vue.config.productionTip = false;

firebase.initializeApp(FirebaseConfig);
firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);

firebase.analytics();

new Vue({
    i18n,
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app');