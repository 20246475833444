<template>
  <div>
    <v-row>
      <v-col align-self="center" cols="1">
        <v-icon @click="$emit('close')"> mdi-arrow-left </v-icon>
      </v-col>
      <v-col cols="11"
        ><h2>{{ group.name }}</h2></v-col
      >
    </v-row>
    <DeviceList :devices="devices" />
  </div>
</template>

<script>
import DeviceList from "../../../../components/devices/DevicesList.vue";

export default {
  props: ["groupId"],
  components: { DeviceList },
  created() {
    this.getGroup();
    this.getDevices();
  },
  methods: {
    getGroup() {
      this.group = this.$store.getters["custSetup/groups"].find(
        (group) => group.id === this.groupId
      );
    },
    getDevices() {
      this.devices = this.$store.getters["custSetup/devicesOfGroup"](
        this.groupId
      );
    },
  },
  data: () => {
    return {
      group: {
        name: "",
      },
      devices: null,
    };
  },
};
</script>