export const MqttBrokerConfig = Object.freeze({
    HOST: "platform.kryptonite-bvba.be",
    PORT: 1884,
    SecurePort: 1894
});

export const ServerConfig = Object.freeze({
    DOMAIN: "platform.kryptonite-bvba.be",
    API_VERSION: "1.0",
    Port: 1337,
    SecurePort: 1338
});

export const ClientConfig = Object.freeze({
    DOMAIN: "platform.kryptonite-bvba.be"
});

export const FirebaseConfig = Object.freeze({
    apiKey: "AIzaSyCEsn-TKmp3-TKQ6UQFHtsPx8j0P6WsKcU",
    authDomain: "mqtt-302914.firebaseapp.com",
    databaseURL: "https://mqtt-302914-default-rtdb.europe-west1.firebasedatabase.app/",
    projectId: "mqtt-302914",
    storageBucket: "mqtt-302914.appspot.com",
    messagingSenderId: "95174844468",
    appId: "1:95174844468:web:8fc190f15053659860ea61",
    measurementId: "G-ZW0Y2D9G0Y"
})

export const ApplicationConfig = Object.freeze({
    companyName: "Kryptonite",
    ApplicationName: "Remote home control"
})