import mutations from "./mutations.js"
import getters from "./getters.js"
import actions from "./actions.js"

export default {
    namespaced: true,
    state: {
        groups: [{
            id: '',
            name: '',

        }],
        devices: [{
            topics:
                [{
                    topicName: '',
                    type: "",
                    value: 0
                }
                ],
            displayName: '',
            type: '',
            groupId: '',
            isLive: false,
            isFavorite: false,
            id: 0
        }]
    },
    mutations,
    getters,
    actions
}