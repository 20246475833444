<template>
  <v-container fluid fill-height>
    <v-layout wrap fill-height align-center justify-center column>
      <v-flex xs12>
          <v-alert v-show="success" elevation="4" dismissable>
              {{$t("messages.success.invitedUser")}}
          </v-alert>
        <v-alert v-show="!!error" elevation="4" type="error">
          {{ error }}
        </v-alert>
      </v-flex>
      <v-card class="mx-auto" max-width="344">
        <v-card-text>
          <v-text-field
            :label="$t('labels.firstName')"
            hide-details="auto"
            v-model="firstName"
            @keydown.enter="invite"
          ></v-text-field>
          <v-text-field
            :label="$t('labels.lastName')"
            hide-details="auto"
            v-model="lastName"
            @keydown.enter="invite"
          ></v-text-field>
          <v-text-field
            :label="$t('labels.email')"
            hide-details="auto"
            v-model="email"
            @keydown.enter="invite"
          ></v-text-field>
        </v-card-text>
      </v-card>
    </v-layout>
  </v-container>
</template>

<script>
import { ApplicationConfig } from '../../config';

export default {
  data() {
    return {
      email: null,
      error: null,
      firstName: null,
      lastName: null,
      success: false
    };
  },
  methods: {
    async invite() {
      try {
          const password = Math.random().toString(36).substr(2);
          const payload = {
              email: this.email,
              password: password
          }
        await this.$store.dispatch("auth/signup", payload);
        const invitePayload = {
          from_name: ApplicationConfig.companyName,
          to_name: this.firstName + ' ' + this.lastName,
          ...payload
        }
        await this.$store.dispatch("auth/invite", invitePayload);
        this.success = true
      } catch (e) {
        this.error = e.response.statusText;
      }
    }
  },
};
</script>