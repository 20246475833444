import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: "#67B22F",
                secondary: "#9c27b0",
                accent: "#607d8b",
                error: "#f44336",
                warning: "#ff9800",
                info: "#00bcd4",
                success: "#4caf50"
            },
        },
    },
});