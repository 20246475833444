<template>
  <button @click="$emit('click')" :class="buttonStyle">
    <!-- <Icon :fill="fill" /> -->
    <!-- <component :is="showIcon ? 'Icon' : 'NoIcon'" :fill="fill" /> -->
    <v-icon :x-large="size === 'large'" color="amber lighten-4">mdi-{{icon}}</v-icon>
  </button>
</template>

<script>
// import Icon from "../icons/IconPowerSwitch.vue";
// import NoIcon from "../icons/IconCircle.vue";

export default {
  // components: { Icon, NoIcon },
  props: {
    isActive: {
      type: Boolean,
      default: false
    },
    showIcon: {
      type: Boolean,
      required: true
    },
    size: {
      type: String,
      default: "large"
    }
  },
  computed: {
    buttonStyle() {
      return {
        'green-bg-gradient': this.isActive && this.showIcon,
        'red-bg-gradient': !this.isActive && this.showIcon,
        'blue-bg-gradient': !this.showIcon,
        'normal-size': this.size !== "large",
        'large-size': this.size === "large"
      }
    },
    icon() {
      return !this.showIcon ? 'pulse' : 'power'
    }
  }
};
</script>

<style scoped>
button {
  display: inline-block;
  text-decoration: none;

  border-radius: 50%;
  text-align: center;
  vertical-align: middle;
  overflow: hidden;
  box-shadow: inset 0 2px 0 rgba(255, 255, 255, 0.5), 0 2px 2px rgba(0, 0, 0, 0.19);
  border-bottom: solid 2px #b5b5b5;
}

.red-bg-gradient {
  background-image: -webkit-linear-gradient(310deg, #ea0606, #ff667c);
  background-image: linear-gradient(310deg, #ea0606, #ff667c);
  background: linear-gradient(310deg, #ea0606, #ff667c);
}

.green-bg-gradient {
  background-image: -webkit-linear-gradient(310deg, #17ad37, #98ec2d);
  background-image: linear-gradient(310deg, #17ad37, #98ec2d);
  background: linear-gradient(310deg, #17ad37, #98ec2d);
}

.blue-bg-gradient {
  background-image: -webkit-linear-gradient(310deg, #177dad, #2decdc);
  background-image: linear-gradient(310deg, #177dad, #2decdc);
  background: linear-gradient(310deg, #177dad, #2decdc);
}

.normal-size {
  width: 50px;
  height: 50px;
}

.large-size {
  width: 80px;
  height: 80px;
}

button:active {
  /* background: #fff;
  box-shadow: 0px 0px 8px 7px rgba(0, 0, 0, 0.06),inset 0px 0px 17px 2px rgba(0, 0, 0, 0.15);
  border-radius: 28px; */
  background-image: -webkit-linear-gradient(#efefef 0%, #d6d6d6 100%);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.5), 0 2px 2px rgba(0, 0, 0, 0.19);
  border-bottom: none;
}
</style>