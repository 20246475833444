<template>
  <v-card elevation="0">
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-alert v-show="!!successMessage" elevation="4" type="success">
            {{ successMessage }}
          </v-alert>
          <v-alert v-show="!!errorMessage" elevation="4" type="error">
            {{ errorMessage }}
            <template v-slot:close>
              <div id="close_btn">
                <v-btn
                  color="rgba(0,0,0,0)"
                  depressed
                  fab
                  x-small
                  @click="hideErrorAlert"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </div>
            </template>
          </v-alert>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12"><h1>{{$t("labels.profile")}}</h1></v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <CredentialsForm
            :email="email"
            @error="showErrorMessage"
            @success="showSuccessMessage"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import CredentialsForm from "../views/pages/Profile/CredentialsForm";

export default {
  components: { CredentialsForm },
  data() {
    return {
      email: null,
      successMessage: "",
      errorMessage: "",
    };
  },
  created() {
    this.email = this.$store.getters["auth/email"];
  },
  methods: {
    showErrorMessage(value) {
      this.errorMessage = value.message;
    },
    showSuccessMessage(value) {
      this.successMessage = value.message;
    },
    hideErrorAlert() {
      this.errorMessage = "";
    },
  },
};
</script>