<template>
  <div>
    <v-alert color="red" dense text type="error" v-if="showError">{{
      error.message
    }}</v-alert>
    <v-layout v-if="showSpinner" align-center justify-center column fill-height>
      <v-flex row align-center>
        <v-progress-circular
          :size="70"
          :width="7"
          indeterminate
        ></v-progress-circular>
      </v-flex>
    </v-layout>

    <v-card elevation="0" v-if="showTabs">
      <v-container class="overview">
        <v-tabs centered @change="showTabContent" class="mb-3">
          <v-tab> {{ $t("labels.favorites") }} </v-tab>
          <v-tab> {{ $t("labels.groups") }} </v-tab>
          <!-- <v-tab> {{$t("labels.dashboardTabs.scenes")}} </v-tab> -->
        </v-tabs>

        <!-- <Groups v-show="showGroups"/> -->
        <router-view v-if="!isLoading"></router-view>
      </v-container>
    </v-card>
  </div>
</template>

<script>
export default {
  data: () => {
    return {
      isLoading: true,
      error: null,
      selectedTab: 0,
      initError: false,
    };
  },
  computed: {
    showError() {
      return this.error !== null;
    },
    showSpinner() {
      return !this.showError && this.isLoading;
    },
    showTabs() {
      return !this.showError && !this.isLoading && !this.showSpinner;
    },
  },
  async created() {
    await this.init();
    if (!this.initError) {
      this.initializeMqtt();
    }
    this.showTabContent();
  },
  beforeUpdate() {
    if (this.$router.currentRoute.name === "Dashboard") {
      this.$router.replace({
        name: "Favorites",
      });
    }
  },
  async beforeDestroy() {
    await this.$store.dispatch("mqtt/disconnectFromBroker");
  },
  methods: {
    async init() {
      try {
        await this.$store.dispatch("custSetup/loadCustomerSetup");
      } catch (e) {
        this.setError(e.message);
        this.initError = true;
      }
      this.selectedTab = this.$router.currentRoute.name !== "Favorites" ? 1 : 0;
    },
    showTabContent(n) {
      if (n === 0 && this.$router.currentRoute.name !== "Favorites") {
        this.$router.replace({
          name: "Favorites",
        });
      } else if (n === 1) {
        this.$router.replace({
          name: "Groups",
        });
      }
    },
    async initializeMqtt() {
      this.isLoading = true;
      try {
        await this.$store.dispatch("mqtt/connectToBroker");

        //Listen for MQTT events
        this.listenForErrorEvent();
        this.listenForReconnectEvent();
        this.listenForCloseEvent();
        this.listenForOfflineEvent();
        this.listenForEndEvent();

        //When the connection to the broker was successfull, start subscribing to topics
        this.listenForConnectedEvent();

        //Wait for messages
        this.listenForMessageEvent();
      } catch (error) {
        this.setError(
          error.message || this.$t("messages.error.mqtt.cannotConnectToBroker")
        );
      }
    },
    listenForConnectedEvent() {
      this.$store.getters["mqtt/mqttClient"].on("connect", async () => {
        //Subscribe to topics
        this.subscribeToTopics();
      });
    },
    listenForErrorEvent() {
      this.$store.getters["mqtt/mqttClient"].on("error", (error) => {
        this.setError(JSON.stringify(error));
      });
    },
    listenForReconnectEvent() {
      this.$store.getters["mqtt/mqttClient"].on("reconnect", () => {
        this.setError(
          this.$t("messages.error.mqtt.connectionToBrokerDisconnectedWithRetry")
        );
      });
    },
    listenForCloseEvent() {
      this.$store.getters["mqtt/mqttClient"].on("disconnect", () => {
        this.setError(
          this.$t("messages.error.mqtt.connectionToBrokerDisconnected")
        );
      });
    },
    listenForOfflineEvent() {
      this.$store.getters["mqtt/mqttClient"].on("offline", () => {
        this.setError(this.$t("messages.error.mqtt.cannotConnectToBroker"));
      });
    },
    listenForEndEvent() {
      this.$store.getters["mqtt/mqttClient"].on("end", () => {
        this.setError(
          this.$t("messages.error.mqtt.connectionToBrokerDisconnected")
        );
      });
    },
    subscribeToTopics() {
      const topics = this.$store.getters["custSetup/getTopicsToSubscribe"];
      if (topics) {
        console.log('Subscribe to topics: ' + topics);
        this.$store.getters["mqtt/mqttClient"].subscribe(
          topics,
          { qos: 1 },
          (err) => {
            if (!err) {
              this.isLoading = false;
              this.clearError();
            } else {
              this.setError(this.$t("messages.error.mqtt.unableToLoadSetup"));
            }
          }
        );
      } else {
        this.setError(this.$t("messages.error.mqtt.noDevicesInSetup"));
      }
    },
    listenForMessageEvent() {
      this.$store.getters["mqtt/mqttClient"].on("message", (topic, message) => {
        console.log("Received message for topic: " + topic + ": " + message);
        this.$store.dispatch("custSetup/setTopicValue", {
          topicName: topic,
          value: message.toString(),
        });
        console.log(topic + ": " + message);
      });
    },
    setError(message) {
      this.error = { message };
    },
    clearError() {
      this.error = null;
    },
  },
};
</script>

<style scoped>
.overview {
  max-width: 800px;
}
</style>