<template>
  <v-flex xs12 sm12 md6>
    <v-card class="mx-auto">
      <v-container>
        <v-row>
          <v-col cols="1">
            <IconBase>
              <temperatureIcon />
            </IconBase>
          </v-col>
          <v-col cols="6">
            <div class="text-h6 title">{{ device.displayName }}</div>
          </v-col>
          <v-col v-if="device.isLive && canPublish" class="d-flex" cols="5">
            <v-spacer></v-spacer>
            <v-btn fab icon height="40px" right width="40px" @click="decrement">
              <v-icon large> mdi-minus </v-icon>
            </v-btn>
            <v-btn class="ml-2" fab icon height="40px" right width="40px" @click="increment">
              <v-icon large> mdi-plus </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>

      <v-card-text>
        <div v-if="device.isLive">
          <v-row class="mb-4" justify="space-between">
            <v-col class="text-left">
              <span class="display-3 font-weight-light" v-text="deviceValue"></span>
              <span class="subheading font-weight-light mr-1">°C</span>
              <span v-if="canPublish" :class="displayToValue">
                <span class="subheading font-weight-light ml-5 mr-5">></span>
                <span class="display-1 font-weight-light" :class="toValueTextStyle" v-text="toValue"></span>
                <span class="subheading font-weight-light mr-1" :class="toValueTextStyle">°C</span>
              </span>
            </v-col>
          </v-row>
        </div>
        <v-tooltip v-else bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="amber lighten-1" v-bind="attrs" v-on="on">
              mdi-alert
            </v-icon>
          </template>
          <span class="white-text">{{ $t("messages.alert.cannotConnectToTopic") }}</span>
        </v-tooltip>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <favoriteIcon :favorite="device.isFavorite" @click="toggleFavorite" />
      </v-card-actions>
    </v-card>
  </v-flex>
</template>

<script>
import favoriteIcon from "../icons/favorite.vue";
import IconBase from "../icons/IconBase";
import temperatureIcon from "../icons/IconTemperature";

export default {
  props: ["device"],
  components: { IconBase, favoriteIcon, temperatureIcon },
  data: () => {
    return {
      toTemperature: 0,
      minTemperature: 5,
      maxTemperature: 100
    };
  },
  computed: {
    deviceValue() {
      const topicIndex = this.getTopicIndex("subscriber");
      return parseFloat(this.device.topics[topicIndex].value);
    },
    toValue() {
      if (this.canPublish) {
        const topicIndex = this.getTopicIndex("publisher");
        return parseFloat(this.device.topics[topicIndex].value) / 100;
      } else {
        return null;
      }
    },
    toValueTextStyle() {
      return {
        "red--text": this.deviceValue < this.toValue,
        "blue--text": this.deviceValue > this.toValue,
      };
    },
    displayToValue() {
      return {
        "d-none": this.deviceValue === this.toValue,
      };
    },
    canPublish() {
      return this.getTopicIndex("publisher") >= 0;
    },
  },
  created() {
    this.toTemperature = this.toValue;
  },
  watch: {
    toValue() {
      if (this.canPublish) {
        const topicIndex = this.getTopicIndex("publisher");
        this.toTemperature =
          parseFloat(this.device.topics[topicIndex].value) / 100;
      }
    },
  },
  methods: {
    decrement() {
      if (this.toTemperature !== this.minTemperature) {
        this.toTemperature = this.toTemperature - 0.5;
      }
      this.sendToBroker();
    },
    increment() {
      if (this.toTemperature !== this.maxTemperature) {
        this.toTemperature = this.toTemperature + 0.5;
      }
      this.sendToBroker();
    },
    sendToBroker() {
      if (this.canPublish) {
        const pubTopicIndex = this.getTopicIndex("publisher");
        const pubTopicName = this.device.topics[pubTopicIndex].topicName;

        //Send to broker
        this.$store.getters["mqtt/mqttClient"].publish(
          pubTopicName,
          "" + this.toTemperature * 100,
          { qos: 1, retain: true },
          (err) => {
            if (!err) {
              //TODO
            } else {
              //TODO
            }
          }
        );
      }
    },
    async toggleFavorite() {
      await this.$store.dispatch("custSetup/toggleFavorite", {
        deviceId: this.device.id,
      });
    },
    getTopicIndex(type) {
      if (type === "subscriber") {
        return this.device.topics.findIndex(
          (topic) => topic.type === "subscriber"
        );
      } else if (type === "publisher") {
        return this.device.topics.findIndex(
          (topic) => topic.type === "publisher"
        );
      }
    },
  },
};
</script>

<style scoped>
.title {
  color: #607d8b;
}
</style>
