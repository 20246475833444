<template>
  <v-flex xs12 sm12 md6>
    <v-card class="mx-auto">
      <v-container>
        <v-row>
          <v-col cols="1">
            <IconBase><shutterIcon /> </IconBase>
          </v-col>
          <v-col cols="6">
            <div class="text-h6 title">{{ device.displayName }}</div>
          </v-col>
          <v-col v-if="device.isLive" class="d-flex" cols="5">
            <v-spacer></v-spacer>
            <v-btn fab icon height="40px" right width="40px" @click="close">
              <v-icon x-large> mdi-chevron-down </v-icon>
            </v-btn>
            <v-btn fab icon height="40px" right width="40px" @click="stop">
              <v-icon x-large> mdi-stop </v-icon>
            </v-btn>
            <v-btn fab icon height="40px" right width="40px" @click="open">
              <v-icon x-large> mdi-chevron-up </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>

      <v-card-text>
        <div v-if="device.isLive">
          <v-row class="mb-4" justify="space-between">
            <v-col class="text-left">
              <span
                class="display-2 font-weight-light"
                v-text="deviceValue"
              ></span>
            </v-col>
          </v-row>
        </div>
        <v-tooltip v-else bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="amber lighten-1" v-bind="attrs" v-on="on">
              mdi-alert
            </v-icon>
          </template>
          <span class="white-text">{{
            $t("messages.alert.cannotConnectToTopic")
          }}</span>
        </v-tooltip>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <favoriteIcon :favorite="device.isFavorite" @click="toggleFavorite" />
      </v-card-actions>
    </v-card>
  </v-flex>
</template>

<script>
import favoriteIcon from "../icons/favorite.vue";
import IconBase from "../icons/IconBase";
import shutterIcon from "../icons/IconShutter";

export default {
  props: ["device"],
  components: { IconBase, favoriteIcon, shutterIcon },
  computed: {
    deviceValue() {
      const topicIndex = this.device.topics.findIndex(
        (topic) => topic.type === "subscriber"
      );
      const state = this.device.topics[topicIndex].value;
      if (state === "closed") {
        return "Gesloten";
      } else if (state === "opened") {
        return "Geopend";
      } else if (state === "close") {
        return "Sluiten";
      }else if (state === "open") {
        return "Openen";
      } else {
        return "Gepauzeerd";
      }
    },
  },
  methods: {
    close() {
      this.sendToBroker("close");
    },
    open() {
      this.sendToBroker("open");
    },
    stop() {
      this.sendToBroker("pause");
    },
    sendToBroker(value) {
      let pubTopicIndex = null;
      if(value === "close") {
        pubTopicIndex = this.getTopicIndex("publisher_close");
      } else if (value === "open") {
        pubTopicIndex = this.getTopicIndex("publisher_open");
      } else if (value === "pause") {
        pubTopicIndex = this.getTopicIndex("publisher_pause");
      }
      const pubTopicName = this.device.topics[pubTopicIndex].topicName;

      //Send to broker
      this.$store.getters["mqtt/mqttClient"].publish(
        pubTopicName,
        "1",
        { qos: 1, retain: true },
        (err) => {
          if (!err) {
            //TODO
          } else {
            //TODO
          }
        }
      );
    },
    async toggleFavorite() {
      await this.$store.dispatch("custSetup/toggleFavorite", {
        deviceId: this.device.id,
      });
    },
    getTopicIndex(type) {
      return this.device.topics.findIndex((topic) => topic.type === type);
    },
  },
};
</script>

<style scoped>
.title {
  color: #607d8b;
}
</style>
