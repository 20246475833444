<template>
  <v-main>
    <v-container fluid fill-height>
      <v-layout fill-height align-center justify-center column>
        <v-flex xs12>
          <v-alert v-show="!!successMessage" elevation="4" type="success">
            {{ successMessage }}
          </v-alert>
          <v-alert v-show="!!error" elevation="4" type="error">
            {{ error }}
            <template v-slot:close>
              <div id="close_btn">
                <v-btn
                  color="rgba(0,0,0,0)"
                  depressed
                  fab
                  x-small
                  @click="hideErrorAlert"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </div>
            </template>
          </v-alert>
        </v-flex>
        <AuthenticationForm
          v-if="showAuthenticationForm"
          :mode="mode"
          @submit="handleAuthenticationFormSubmit"
          :loading="loading"
          :emailDefault="email"
        />
        <ResetPasswordForm
          v-else
          @submit="handleResetPasswordSubmit"
          :loading="loading"
        />
      </v-layout>
    </v-container>
  </v-main>
</template>

<script>
import AuthenticationForm from "../components/pages/Auth/AuthenticationForm";
import ResetPasswordForm from "../components/pages/Auth/ResetPasswordForm";
import { sendResetPasswordEmailRequest } from "../api/user/User.js";

const QUERY_ACCOUNTACTIVATED = "accountActivated";
const QUERY_RESETPASSWORDREQUESTED = "passwordResetRequested";

export default {
  components: { AuthenticationForm, ResetPasswordForm },
  data() {
    return {
      loading: false,
      error: null,
      email: null,
      successMessage: null,
    };
  },
  computed: {
    mode() {
      const routeName = this.$route.name;
      if (routeName === "Login") {
        return "login";
      } else if (routeName === "Signup") {
        const continueUrl = this.$route.query.continueUrl;
        if (continueUrl) {
          if (continueUrl.includes("registreren")) {
            return "signup";
          } else if (continueUrl.includes("wachtwoord-herstellen")) {
            return "resetpassword";
          } else {
            return "login";
          }
        }
      }
      return "login";
    },
    showAuthenticationForm() {
      return !(this.$route.name === "RequestResetPassword");
    },
  },
  watch: {
    mode() {
      this.error = null;
      this.successMessage = null;
    },
  },
  methods: {
    handleAuthenticationFormSubmit(credentials) {
      this.loading = true;
      this.mode === "login"
        ? this.login(credentials)
        : this.signup(credentials);
    },
    async login(credentials) {
      try {
        await this.$store.dispatch("auth/login", credentials);
        this.$router.replace({ name: "Favorites" });
      } catch (e) {
        this.error = e.message;
      }
      this.loading = false;
    },
    async signup(credentials) {
      const payload = {
        password: credentials.password,
        oobCode: this.$route.query.oobCode,
      };
      try {
        const email = await this.$store.dispatch(
          "auth/activateAccount",
          payload
        );
        this.$router.push({
          name: "Login",
          query: {
            origin: QUERY_ACCOUNTACTIVATED,
            email,
          },
        });
      } catch (e) {
        this.error = e.message;
      }
      this.loading = false;
    },
    async handleResetPasswordSubmit(email) {
      try {
        this.loading = true;
        await sendResetPasswordEmailRequest(email);
        this.$router.replace({
          name: "Login",
          query: {
            origin: QUERY_RESETPASSWORDREQUESTED,
            email: email.email,
          },
        });
      } catch (e) {
        this.error = e.message;
      } finally {
        this.loading = false;
      }
    },
    hideErrorAlert() {
      this.error = null;
    },
  },
  created() {
    if (this.mode === "signup" && !this.$route.query.oobCode) {
      this.error = this.$t("messages.error.invalidSignUpProperties");
    }
  },
  beforeUpdate() {
    if (this.mode === "login" && this.$route.query.origin) {
      if (this.$route.query.origin.toString() === QUERY_ACCOUNTACTIVATED) {
        this.successMessage = this.$t("messages.success.accountActived");
      } else if (
        this.$route.query.origin.toString() === QUERY_RESETPASSWORDREQUESTED
      ) {
        this.successMessage = this.$t("messages.success.passwordResetRequested");
      }
      this.email = this.$route.query.email
        ? this.$route.query.email.toString()
        : "";
    }
  },
};
</script>