import firebase from 'firebase/app';
import 'firebase/auth';
import UnverifiedEmailError from '../../../errors/unverifiedEmailError';
import { sendVerificationEmailRequest } from '../../../api/user/User.js';
import { ClientConfig } from '../../../config';
import i18n from '../../../translations/i18n';

export default {
    async login(context, payload) {
        try {
            const response = await firebase.auth().signInWithEmailAndPassword(payload.email, payload.password)
            const user = response.user;
            if (!user.emailVerified) {
                const unverifiedEmailError = new UnverifiedEmailError(i18n.t("messages.error.unverifiedEmail"));
                throw unverifiedEmailError;
            }

            context.commit('setUser', response.user)
        } catch (e) {
            let error;
            if (e.name === "UnverifiedEmailError") {
                error = e;
            } else {
                error = new Error(i18n.t("messages.error.invalidEmailOrPassword"))
            }

            throw error
        }
    },
    async signup(context, payload) {
        try {
            await firebase.auth().createUserWithEmailAndPassword(payload.email, payload.password)
        } catch (e) {
            let error = null;
            if (e.code === 'auth/email-already-in-use') {
                error = new Error(i18n.t("messages.error.firebase.emailAlreadyInUse"));
            } else {
                error = new Error(i18n.t("messages.error.firebase.generalSignupError"));
            }
            throw error
        }
    },
    async invite(context, payload) {
        const loginUrl = `https://www.${ClientConfig.DOMAIN}/aanmelden`;

        try {
            await sendVerificationEmailRequest({
                login_url: loginUrl,
                ...payload
            });

        } catch (e) {
            try {
                const currentUser = firebase.auth().currentUser;
                if (currentUser.emailVerified === false && currentUser.email === payload.email) {
                    currentUser.delete();
                }
            } catch (e) {
                const error = new Error(i18n.t("messages.error.firebase.unableToInvite"));
                throw error;
            }
            throw e;
        }
    },
    async activateAccount(context, payload) {
        let email = null;
        try {
            email = await firebase.auth().verifyPasswordResetCode(payload.oobCode);
        } catch (error) {
            const e = new Error(i18n.t("messages.error.firebase.invalidVerificationCode"));
            throw e;
        }
        try {
            await firebase.auth().confirmPasswordReset(payload.oobCode, payload.password);
        } catch (error) {
            const e = new Error(i18n.t("messages.error.firebase.unableToSetPassword"));
            throw e;
        }
        return email;
    },
    async setNewPassword(context, credentials) {

        //Reauthenticate
        const reAuthenticateCredentials = firebase.auth.EmailAuthProvider.credential(
            credentials.email, credentials.password
        );
        const user = await firebase.auth().currentUser;
        try {
            await user.reauthenticateWithCredential(reAuthenticateCredentials);
        } catch (e) {
            throw new Error(i18n.t("messages.error.invalidCurrentPassword"));
        }

        try {
            await user.updatePassword(credentials.newPassword);
        } catch (error) {
            throw new Error(i18n.t("messages.error.unableToChangePassword"));
        }
    },
    async logout(context) {
        try {
            await firebase.auth().signOut();
            context.commit('setUser', null);
        } catch (error) {
            console.error("Error signing out: ", error);
        }
    },
    async tryLogin(context) {
        firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                context.commit('setUser', user);
            } else {
                context.commit('setUser', null);
            }
        });
    }
}