<template>
  <v-container>
    <v-row justify="center">
      <v-col sm="8" md="4">
        <v-img src="@/assets/notfound.png" alt="Page not found" />
      </v-col>
    </v-row>
    <v-row justify="center" v-if="!isAuthenticated">
      <v-col class="flex-grow-0">
        <v-btn color="primary" elevation="2" :to="{ name: 'Login' }"
          >{{$t("labels.authentication.login")}}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  computed: {
    isAuthenticated() {
      return this.$store.getters["auth/isAuthenticated"];
    },
  },
};
</script>