export default {
    groups(state) {
        return state.groups
    },
    devices(state) {
        return state.devices
    },
    getTopicsToSubscribe(state) {
        let topicNames = []
        if (state.devices.length > 0) {
            state.devices.forEach(device => {
                if (device.topics && device.topics.length > 0) {
                    device.topics.forEach(topic => {
                        topicNames.push(topic.topicName)
                    })
                }
            });
        }
        else {
            return null;
        }
        return topicNames.length > 0 ? topicNames : null;
    },
    devicesOfGroup: (state) => (id) => {
        let devices = state.devices.filter(device =>
            device.groupId == id
        );

        return devices
    },
    favoriteDevices(state) {
        console.log(state.devices)
        let devices = state.devices.filter(device =>
            device.isFavorite
        );

        return devices
    }
}