<template>
  <v-flex grow xs12>
    <v-card class="elevation-6">
      <v-toolbar elevation="0" class="bottom-line">
        <v-toolbar-title>{{ formTitle }}</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-form @submit.prevent="handleFormSubmit">
          <v-text-field
            v-show="showEmailAndPassword"
            prepend-icon="mdi-email"
            name="login"
            :label="$t('labels.email')"
            type="text"
            v-model="email"
          ></v-text-field>
          <v-text-field
            id="password"
            prepend-icon="mdi-lock"
            name="password"
            :label="$t('labels.password')"
            type="password"
            v-model="password"
          ></v-text-field>
          <v-text-field
            v-show="!showEmailAndPassword"
            id="passwordCheck"
            prepend-icon="mdi-lock"
            name="passwordCheck"
            :label="$t('labels.confirmNewPassword')"
            type="password"
            v-model="passwordCheck"
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-btn type="submit" color="primary" :loading="loading">{{
            buttonLabel
          }}</v-btn>
        </v-form>
        <div class="d-flex flex-row" v-if="showPasswordForgottenLink">
          <v-spacer></v-spacer>
          <router-link
            :to="{ name: 'RequestResetPassword' }"
            class="text-decoration-none mt-5"
            >{{$t("labels.authentication.passwordForgotten")}}</router-link
          >
        </div>
      </v-card-text>
      <v-alert v-show="isValid === false" elevation="4" type="error">
        <ul>
          <li v-for="error in errorMessages" :key="error">
            {{ error }}
          </li>
        </ul>
      </v-alert>
    </v-card>
  </v-flex>
</template>

<script>
import {
  validateEmail,
  validatePassword,
} from "../../../utils/formValidations";

export default {
  props: {
    mode: {
      type: String,
      validator: function (value) {
        // The value must match one of these strings
        return ["login", "signup", "resetpassword"].indexOf(value) !== -1;
      },
    },
    loading: {
      type: Boolean,
      default: false,
    },
    emailDefault: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      email: null,
      password: null,
      passwordCheck: null,
      isValid: null,
      emailIsValid: null,
      passwordIsValid: null,
      passwordCheckIsValid: null,
      errorMessages: [],
    };
  },
  watch: {
    mode() {
      this.email = this.emailDefault;
      this.password = "";
    },
    emailDefault() {
      this.email = this.emailDefault;
    },
  },
  computed: {
    buttonLabel() {
      if (this.mode === "login") {
        return this.$t("labels.authentication.login");
      } else if (this.mode === "signup") {
        return this.$t("labels.authentication.signup");
      } else if (this.mode === "resetpassword") {
        return this.$t("labels.authentication.resetpassword");
      }
      return "";
    },
    formTitle() {
      if (this.mode === "login") {
        return this.$t("forms.authentication.login");
      } else if (this.mode === "signup") {
        return this.$t("forms.authentication.signup");
      } else if (this.mode === "resetpassword") {
        return this.$t("forms.authentication.resetpassword");
      }
      return "";
    },
    showEmailAndPassword() {
      return this.mode === "login";
    },
    showPasswordForgottenLink() {
      return this.mode ==='login';
    }
  },
  methods: {
    handleFormSubmit() {
      this.errorMessages = [];
      this.isValid = this.validateForm();
      if (this.isValid) {
        this.$emit("submit", {
          email: this.email,
          password: this.password,
        });
      }
    },
    validateForm() {
      if (this.mode === "login") {
        if (!this.email || !this.password) {
          this.errorMessages.push(this.$t("messages.error.inputEmailAndPassword"));
          return false;
        }
        this.emailIsValid = validateEmail(this.email);
      }
      this.passwordIsValid = validatePassword(this.password);
      if (this.mode === "signup" || this.mode === "resetpassword") {
        this.passwordCheckIsValid = this.password === this.passwordCheck;
      }

      if (this.mode === "login" && !this.emailIsValid) {
        this.errorMessages.push(this.$t("messages.alert.invalidEmail"));
      }
      if (!this.passwordIsValid) {
        this.errorMessages.push(this.$t("messages.alert.invalidPassword"));
      } else if (
        (this.mode === "signup" || this.mode === "resetpassword") &&
        !this.passwordCheckIsValid
      ) {
        this.errorMessages.push(this.$t("messages.alert.invalidPasswordCheck"));
      }
      if (this.mode === "login") {
        return this.emailIsValid && this.passwordIsValid;
      } else {
        return this.passwordCheckIsValid && this.passwordIsValid;
      }
    },
  },
};
</script>

<style>
.bottom-line {
  border-bottom: thin solid rgba(0, 0, 0, 0.12) !important;
}
</style>