<template>
  <div>
    <!-- <h1>Ruimten</h1> -->
    <Overview
      :groups="groups"
      @groupSelected="groupSelected"
      v-if="!selectedGroup"
    />
    <Group v-else :groupId="selectedGroup.id" @close="closeGroup" />
  </div>
</template>

<script>
import Overview from "./GroupList.vue";
import Group from "./GroupDetail.vue";

export default {
  components: { Overview, Group },
  name: "Groups",
  created() {
    this.loadGroups();
  },
  methods: {
    async loadGroups() {
      try {
      this.groups = this.$store.getters["custSetup/groups"];
      }
      catch (error) {
        //TODO Use global error screen
      }
    },
    groupSelected(id) {
      this.selectedGroup = this.groups.find((group) => group.id === id);
    },
    closeGroup() {
      this.selectedGroup = null;
    },
  },
  data: () => {
    return {
      selectedGroup: null,
      groups: null,
    };
  },
};
</script>