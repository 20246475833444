import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import i18n from '../../../translations/i18n';

export default {
    async loadCustomerSetup(context) {
        const databaseReference = firebase.database().ref();
        const userUid = context.rootState.auth.user.uid;

        const profileLoadingError = new Error(i18n.t("messages.error.mqtt.unableToLoadSetup"));
        let setupDetails;
        let user;

        try {
            user = (await databaseReference.child('Users').child(userUid).get()).val();
            if (!user) throw profileLoadingError;
            setupDetails = (await databaseReference.child('CustomerSetups').child(user.Setup).get()).val();
            if (!setupDetails || !setupDetails.BrokerConnectionDetails) throw profileLoadingError

        } catch (e) {
            throw profileLoadingError;
        }
        context.commit('setGroups', setupDetails.Groups);
        if (user.favoriteDevices) {
            const favoriteDevices = Object.keys(user.favoriteDevices);
            if (favoriteDevices && favoriteDevices.length > 0) {
                favoriteDevices.forEach(value => {
                    const deviceIndex = setupDetails.Devices.findIndex(device =>
                        +device.id === +value
                    )
                    if (deviceIndex >= 0) {
                        setupDetails.Devices[deviceIndex].isFavorite = true;
                    }
                })
            }
        }
        context.commit('setDevices', setupDetails.Devices);
        context.dispatch('mqtt/storeMqttCredentials', setupDetails.BrokerConnectionDetails, { root: true })
    },
    setTopicValue(context, payload) {
        context.commit('setTopicValue', payload)
    },
    async toggleFavorite(context, payload) {
        //Also send to server
        const devices = context.getters.devices;
        const deviceIndex = devices.findIndex((obj => obj.id == payload.deviceId));


        const userUid = context.rootState.auth.user.uid;
        const databaseRef = firebase.database().ref('Users/' + userUid + '/favoriteDevices/' + payload.deviceId);

        try {
            if (devices[deviceIndex].isFavorite) {
                //Remove from favoriteDevice
                databaseRef.remove();
            } else {
                //Add to favoriteDevices
                databaseRef.set(true)
            }
        } catch (e) {
            const error = new Error(i18n.t("messages.error.unableToChangeFavorite"));
            throw error;
        }

        context.commit('toggleFavorite', {
            deviceIndex,
            value: !devices[deviceIndex].isFavorite
        })
    }
}