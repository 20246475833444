import i18n from "../../../translations/i18n";
import Vue from 'vue';

export default {
    setGroups(state, payload) {
        state.groups = payload
    },

    setDevices(state, payload) {
        state.devices = payload
    },
    setTopicValue(state, payload) {
        let updated = false;
        state.devices.forEach((device, i) => {
            const objIndex = device.topics.findIndex((obj => obj.topicName == payload.topicName))
            if (objIndex >= 0) {
                state.devices[i].topics[objIndex].value = payload.value
                Vue.set(state.devices[i], 'isLive', true);
                // state.devices[i].isLive = true
                updated = true
            }
        });
        if (!updated) {
            const error = new Error(i18n.t("messages.error.mqtt.unableToUpdateTopicValue") + ' ' + payload.topicName);
            throw error;

        }
    },
    toggleFavorite(state, payload) {
        state.devices[payload.deviceIndex].isFavorite = payload.value;
    }
}