export default {
    setMqttClient(state, payload) {
        state.mqttClient = payload.client
    },
    endMqttConnection(state) {
        if (state.mqttClient) {
            state.mqttClient.end(true);
        }
    },
    saveMqttCredentialsLocally(state, credentials) {
        state.username = credentials.username;
        state.password = credentials.password;
    }
}